export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_STATUS_USERS = 'SET_STATUS_USERS';

export const LOAD_COMPONENTS = 'LOAD_COMPONENTS';
export const ADD_COMPONENT = 'ADD_COMPONENT';
export const EDIT_COMPONENT = 'EDIT_COMPONENT';
export const DELETE_COMPONENT = 'DELETE_COMPONENT';

export const LOAD_DASHBOARD_METRICS = 'LOAD_COMPONENTS_METRICS';
export const LOAD_ADMIN_METRICS = 'LOAD_ADMIN_METRICS';

export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const SET_POLLING_ID = 'SET_POLLING_ID';
export const SET_VERSION = 'SET_VERSION';

export const LOAD_USER_SUBSCRIPTIONS = 'LOAD_USER_SUBSCRIPTIONS';
export const EDIT_USER_SUBSCRIPTION = 'EDIT_USER_SUBSCRIPTION';
export const DELETE_USER_SUBSCRIPTION = 'DELETE_USER_SUBSCRIPTION';
export const SAVE_USER_SUBSCRIPTION = 'SAVE_USER_SUBSCRIPTION';

export const LOAD_STATUS_SETTINGS = 'LOAD_STATUS_SETTINGS';
export const UPDATE_ALERTS_SETTING = 'UPDATE_ALERTS_SETTING';

export const LOAD_LATEST_INCIDENTS = 'LOAD_LATEST_INCIDENTS';
export const RESOLVE_INCIDENT = 'RESOLVE_INCIDENT';

export const LOAD_CLIENT_CONFIGURATIONS = 'LOAD_CLIENT_CONFIGURATIONS';

export const LOAD_POSTMAN_COLLECTIONS = 'LOAD_POSTMAN_COLLECTIONS';
export const LOAD_POSTMAN_ENVIRONMENTS = 'LOAD_POSTMAN_ENVIRONMENTS';
export const LOAD_POSTMAN_REPORTS = 'LOAD_POSTMAN_REPORTS';
export const SAVE_POSTMAN_REPORT = 'SAVE_POSTMAN_REPORT';
export const UPDATE_POSTMAN_REPORT = 'UPDATE_POSTMAN_REPORT';

export const LOAD_DIAGNOSTICS = 'LOAD_DIAGNOSTICS';
export const DELETE_DIAGNOSTICS = 'DELETE_DIAGNOSTICS';

export const LOAD_CONNECTIONS = 'LOAD_CONNECTIONS';
export const ADD_CONNECTION = 'ADD_CONNECTION';
export const DELETE_CONNECTION = 'DELETE_CONNECTION';
export const EDIT_CONNECTION = 'EDIT_CONNECTION';
