import axios, { AxiosInstance } from 'axios';

export default class Http {
  private client: AxiosInstance;

  constructor(params: any) {
    this.client = axios.create({
      ...params,
    });

    this.client.interceptors.request.use(
      (config: any) => {
        const token = localStorage.getItem('okta-token-storage');
        if (token) {
          const okta = JSON.parse(token);
          if (okta && okta.accessToken) {
            config.headers.Authorization = `Bearer ${okta.accessToken.value}`;
          }
        }
        return config;
      },
      (error: any) => Promise.reject(error),
    );
  }

  public get(url: string, query?: any) {
    return this.client.get(url, query);
  }

  public post(url: string, data: any) {
    return this.client.post(url, data);
  }

  public put(url: string, data: any) {
    return this.client.put(url, data);
  }

  public delete(url: string) {
    return this.client.delete(url);
  }
}
