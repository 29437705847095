












import Vue from 'vue';
import Component from 'vue-class-component';
import { UserService } from '../services';

@Component
export default class LogoutView extends Vue {
  beforeCreate(): void {
    UserService.clearState();
    this.$auth.logout();
  }
}
