






import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { ComponentMetric } from '../../models';
import OverlayLoader from '../shared/OverlayLoader.vue';
import { SET_POLLING_ID } from '../../store/Mutations';
import { GetSortOrder } from '../../helpers/Common';
import ComponentTiles from '../shared/ComponentTiles.vue';

@Component({
  components: {
    OverlayLoader,
    ComponentTiles,
  },
})
export default class DashboardList extends Vue {
  pollingId: any;
  isLoading: boolean = false;
  readonly interval: number = 30000;

  @Watch('environment')
  onEnvironmentChange(value: string, old: string) {
    this.isLoading = true;
    this.$store.dispatch('loadDashboardMetrics', this.environment)
      .then(() => {
        this.isLoading = false;
        this.startStatusListening();
      });
  }

  get metrics(): ComponentMetric[] {
    return this.$store.getters.allMetrics
      .filter((f: ComponentMetric) => f.isValid)
      .sort(GetSortOrder('name'));
  }

  get environment(): string {
    return this.$store.state.environment;
  }

  mounted() {
    this.isLoading = true;
    this.$store.dispatch('loadDashboardMetrics', this.environment)
      .then(() => {
        this.isLoading = false;
        this.startStatusListening();
      });
  }

  beforeDestroy() {
    clearInterval(this.pollingId);
  }

  startStatusListening(): void {
    // clear the old environment polling if present
    const id = this.$store.state.pollingId;
    if (id) {
      clearInterval(id);
    }

    this.pollingId = setInterval(
      () => this.$store.dispatch('loadDashboardMetrics', this.environment),
      this.interval,
    );
    this.$store.commit(SET_POLLING_ID, this.pollingId);
  }
}
