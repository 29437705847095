import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';

Vue.config.productionTip = false;

// Vue.use(VueAppInsights, {
//  id: '736c117f-bf52-45e7-8bfe-a28b0b9b98d2',
//  router,
// });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
