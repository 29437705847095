






import Vue from 'vue';
import Component from 'vue-class-component';

import Navigation from './Navigation.vue';
import Header from './Header.vue';

@Component({
  components: {
    Navigation,
    Header,
  },
})
export default class Layout extends Vue {
  isHeader: boolean = true;

  mounted() {
    if (this.$route.name === 'fullView') {
      this.isHeader = false;
    }
  }
}
