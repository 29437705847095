import moment from 'moment';

export default class Incident {
  id: string;
  start: string;
  end?: string;
  lastUpdated: string;
  resolved: boolean;
  affectedComponents: string[]

  constructor(data?: any) {
    this.id = (data && data.id) || '';
    this.start = (data && data.start) || '';
    this.end = (data && data.end) || '';
    this.lastUpdated = (data && data.lastUpdated) || '';
    this.resolved = (data && data.resolved) || false;
    this.affectedComponents = (data && data.affectedComponents) || [];
  }

  get formattedStart(): string {
    return moment(this.start)
      .local()
      .format('DD/MM/YY HH:MM:ss');
  }

  get formattedLastUpdate(): string {
    return moment(this.lastUpdated)
      .local()
      .format('DD/MM/YY HH:MM:ss');
  }

  public static deserialize(data?: any): Incident {
    const versionData: Partial<Incident> = {
      id: data.id,
      start: data.start,
      end: data.end,
      lastUpdated: data.lastUpdated,
      resolved: data.resolved,
      affectedComponents: data.affectedComponents,
    };
    return new Incident(versionData);
  }

  public static deserializeList(data?: any[]): Incident[] {
    const adUsers: Incident[] = [];
    Array.isArray(data) && data.map(i => adUsers.push(Incident.deserialize(i)));
    return adUsers;
  }
}
