const {
  VUE_APP_ENV,
  VUE_APP_OKTA_CLIENTID,
  VUE_APP_OKTA_URL,
  VUE_APP_OKTA_AUTHSERVERID,
} = process.env;

export default {
  HttpsDisabled: VUE_APP_ENV === 'Local',
  OktaClientId: VUE_APP_OKTA_CLIENTID,
  OktaBaseUrl: `${VUE_APP_OKTA_URL}/oauth2/${VUE_APP_OKTA_AUTHSERVERID}`,
};
