import { ComponentMetric } from '@/models';
import Http from './Http';

class SyncService {
  readonly params = {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  async syncEnvironments(): Promise<boolean> {
    try {
      const response = await this.http.post('/api/syncenvironment', null);
      return response.data.data.success;
    } catch (_) {
      return false;
    }
  }

  async loadStatusServices(): Promise<ComponentMetric[]> {
    try {
      const response = await this.http.get('/api/services/status');
      return ComponentMetric.deserializeList(response.data.data);
    } catch (_) {
      return [];
    }
  }
}

export default new SyncService();
