


















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import OverlayLoader from '../shared/OverlayLoader.vue';
import { ComponentModel } from '../../models';
import regex from '../../helpers/Regex';
import { UserService } from '../../services';
import { PermissionLevel } from '../../enums';

type pathField = {
  labelkey: string,
  valuekey: string,
  labelpath: string,
  valuepath: string,
}

@Component({
  components: {
    OverlayLoader,
  },
})
export default class ComponentForm extends Vue {
  readonly items = [
    { key: 'API', value: 'API' },
    { key: 'Windows Service', value: 'WindowsService' },
    { key: 'Website', value: 'Website' },
    { key: 'Information', value: 'Information' },
  ];
  nameRegex: RegExp = new RegExp(regex.namespace);
  urlRegex: RegExp = new RegExp(regex.urls);
  component: ComponentModel = new ComponentModel();
  initialComponent: string = '';
  isLoading: boolean = false;
  pathFields: pathField[] = [];
  showSnackbar: boolean = false;

  get requiresUrls(): boolean {
    return this.component.type === 'API'
      || this.component.type === 'Website'
      || this.component.type === 'Information';
  }

  // use the data function until vuetify supports class properties
  data() {
    return {
      rules: {
        required: (value: string) => !!value || 'Required.',
        urlsRequired: (value: string) => {
          if (this.requiresUrls) {
            return !!value || 'Required.';
          }
          return true;
        },
        validUrl: (value: string) => {
          if (value && value.length > 0) {
            return this.urlRegex.test(value) || 'Invalid url.';
          }
          return true;
        },
        validName: (value: string) => {
          if (value && value.length > 0) {
            return this.nameRegex.test(value) || 'Invalid Name.';
          }
          return true;
        },
        validNamespace: (value: string) => {
          if (value && value.length > 0) {
            return this.nameRegex.test(value) || 'Invalid Namespace.';
          }
          return true;
        },
      },
    };
  }

  get canLock(): boolean {
    return UserService.hasPermission(PermissionLevel.Admin);
  }

  mounted(): void {
    this.isLoading = true;
    this.$store.dispatch('loadComponents')
      .then(() => {
        const component = this.$store.getters.componentById(
          this.$route?.params?.id,
        );
        if (component) {
          this.component = component;
          Object.entries(component.appSettingPaths).map((arr: [string, any]) => {
            if (arr) {
              this.pathFields.push({
                labelkey: 'AppKey',
                valuekey: arr[0],
                labelpath: 'AppPath',
                valuepath: arr[1],
              });
            }
            return null;
          });
        }

        this.initialComponent = JSON.stringify(component);
        this.isLoading = false;
      });
  }

  addPath(): void {
    this.pathFields.push({
      labelkey: 'AppKey',
      valuekey: '',
      labelpath: 'AppPath',
      valuepath: '',
    });
  }

  removePath(index: number): void {
    this.pathFields.splice(index, 1);
  }

  onSubmit(): void {
    if (this.component.isLocked
      && !UserService.hasPermission(PermissionLevel.Admin)
      && this.component.id
    ) {
      this.showSnackbar = true;
      return;
    }

    const form: any = this.$refs.inputForm;
    if (!form.validate()) {
      return;
    }

    const tmp = {};

    const data = this.pathFields.map(
      (path: any, idx: any) => Object.assign(tmp, { [path.valuekey]: path.valuepath }),
    );

    this.component.appSettingPaths = tmp;

    if (this.initialComponent === JSON.stringify(this.component)) {
      return;
    }
    this.isLoading = true;
    const mode = this.component.id
      ? { action: 'updateComponent', route: '../../components' }
      : { action: 'saveComponent', route: '../components' };

    this.$store.dispatch(mode.action, this.component)
      .then((result: boolean) => {
        this.isLoading = false;
        if (result) {
          this.$router.push(mode.route);
        }
      });
  }
}
