import Subscription from './Subscription';

export default class UserSubscription {
  id: string;
  adId: string;
  name: string;
  username: string;
  createdOn: string;
  subscriptions: Subscription[];
  components: string[];

  constructor(data?: any) {
    this.id = (data && data.id) || '';
    this.adId = (data && data.adId) || '';
    this.name = (data && data.name) || '';
    this.username = (data && data.username) || '';
    this.createdOn = (data && data.createdOn) || '';
    this.subscriptions = (data && data.subscriptions) || [];
    this.components = (data && data.components) || [];
  }

  get phoneNumber(): string {
    const sub = this.findSubscription('sms');
    if (sub) {
      return sub.phoneNumber;
    }
    return '';
  }

  get email(): string {
    const sub = this.findSubscription('email');
    if (sub) {
      return sub.email;
    }
    return '';
  }

  removeSubscription(type: string): void {
    this.subscriptions = this.subscriptions
      .filter(c => c.type.toLowerCase() !== type);
  }

  private findSubscription(type: string): Subscription | undefined {
    return this.subscriptions
      .find((f: Subscription) => f.type.toLowerCase() === type);
  }

  public static deserialize(data?: any): UserSubscription {
    const userSubscription: Partial<UserSubscription> = {
      id: data.id,
      adId: data.adId,
      name: data.name,
      username: data.username,
      createdOn: data.createdOn,
      subscriptions: Subscription.deserializeList(data.subscriptions),
      components: data.components,
    };

    return new UserSubscription(userSubscription);
  }

  public static deserializeList(data?: any[]): UserSubscription[] {
    const userSubscriptions: UserSubscription[] = [];
    Array.isArray(data) && data.map(i => userSubscriptions.push(UserSubscription.deserialize(i)));
    return userSubscriptions;
  }
}
