







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import IncidentList from '@/components/incident/IncidentList.vue';
import IncidentHeader from '@/components/incident/IncidentHeader.vue';

@Component({
  components: {
    IncidentList,
    IncidentHeader,
  },
})
export default class IncidentsView extends Vue {
}
