import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import Auth from '@okta/okta-vue';
import { Dashboard, Incidents } from '@/views';
import store from '@/store/index';
import { SET_ENVIRONMENT, SET_LOGIN_STATUS } from '@/store/Mutations';
import { PermissionLevel } from '@/enums';
import { ENVIRONMENT_TYPES } from '@/helpers/Constants';
import Config from '@/helpers/Config';
import { getDefaultEnvironment } from '@/helpers/Common';

Vue.use(Auth, {
  clientId: Config.OktaClientId,
  issuer: Config.OktaBaseUrl,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid', 'profile'],
  pkce: true,
  testing: {
    disableHttpsCheck: Config.HttpsDisabled,
  },
});
Vue.use(VueRouter);
const defaultEnvironment: string = getDefaultEnvironment();
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "components" */ '@/views/Login.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "components" */ '@/views/Logout.vue'),
  },
  {
    path: '/implicit/callback',
    name: 'callback',
    component: () => import(/* webpackChunkName: "components" */ '@/components/auth/HandleCallback.vue'),
  },
  {
    path: '/',
    redirect: `/dashboard/${defaultEnvironment}`,
  },
  {
    path: '/dashboard',
    redirect: `/dashboard/${defaultEnvironment}`,
  },
  {
    path: '/dashboard/:env',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/incidents',
    redirect: `/dashboard/${defaultEnvironment}`,
  },
  {
    path: '/incidents/:env',
    name: 'incidents',
    component: Incidents,
  },
  {
    path: '/components',
    name: 'components',
    meta: { requiresAuth: true, level: PermissionLevel.Read },
    component: () => import(/* webpackChunkName: "components" */ '@/views/Components.vue'),
  },
  {
    path: '/components/add',
    name: 'add-component',
    meta: { requiresAuth: true, level: PermissionLevel.ReadWrite },
    component: () => import(/* webpackChunkName: "components" */ '@/views/AddComponent.vue'),
  },
  {
    path: '/components/:id/edit',
    name: 'edit-component',
    meta: { requiresAuth: true, level: PermissionLevel.ReadWrite },
    component: () => import(/* webpackChunkName: "components" */ '@/views/AddComponent.vue'),
  },
  {
    path: '/connections',
    name: 'connections',
    meta: { requiresAuth: true, level: PermissionLevel.Read },
    component: () => import(/* webpackChunkName: "connections" */ '@/views/Connections.vue'),
  },
  {
    path: '/connections/add',
    name: 'add-connection',
    meta: { requiresAuth: true, level: PermissionLevel.ReadWrite },
    component: () => import(/* webpackChunkName: "connections" */ '@/views/AddConnection.vue'),
  },
  {
    path: '/connections/:id/edit',
    name: 'edit-connection',
    meta: { requiresAuth: true, level: PermissionLevel.ReadWrite },
    component: () => import(/* webpackChunkName: "connections" */ '@/views/AddConnection.vue'),
  },
  {
    path: '/security',
    name: 'security',
    meta: { requiresAuth: true, level: PermissionLevel.Read },
    component: () => import(/* webpackChunkName: "components" */ '@/views/Security.vue'),
  },
  {
    path: '/fullView/:env',
    name: 'fullView',
    component: Dashboard,
  },
  {
    path: '/alerts',
    name: 'alerts',
    meta: { requiresAuth: true, level: PermissionLevel.Read },
    component: () => import(/* webpackChunkName: "Alert" */ '@/views/Alerts.vue'),
  },
  {
    path: '/alerts/add',
    name: 'add-alert',
    meta: { requiresAuth: true, level: PermissionLevel.ReadWrite },
    component: () => import(/* webpackChunkName: "Alert" */ '@/views/AddAlert.vue'),
  },
  {
    path: '/alerts/:id/edit',
    name: 'edit-alert',
    meta: { requiresAuth: true, level: PermissionLevel.ReadWrite },
    component: () => import(/* webpackChunkName: "Alert" */ '@/views/AddAlert.vue'),
  },
  {
    path: '/diagnostics',
    name: 'diagnostics',
    meta: { requiresAuth: true, level: PermissionLevel.ReadWrite },
    component: () => import(/* webpackChunkName: "Diagnostics" */ '@/views/Diagnostics.vue'),
  },
  {
    path: '/client-configurations/:env',
    name: 'client-configurations',
    meta: { requiresAuth: true, level: PermissionLevel.ReadWrite },
    component: () => import(/* webpackChunkName: "ClientConfigurations" */ '@/views/ClientConfigurations.vue'),
  },
  {
    path: '/frameworks/:env',
    name: 'frameworks',
    meta: { requiresAuth: true, level: PermissionLevel.Read },
    component: () => import(/* webpackChunkName: "Framework" */ '@/views/Framework.vue'),
  },
  {
    path: '/postman',
    name: 'postman',
    meta: { requiresAuth: true, level: PermissionLevel.Read },
    component: () => import(/* webpackChunkName: "Postman" */ '@/views/Postman.vue'),
  },
  {
    path: '/postman/report/:id',
    name: 'postman-report',
    meta: { requiresAuth: true, level: PermissionLevel.Read },
    component: () => import(/* webpackChunkName: "Postman" */ '@/views/PostmanReportData.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    meta: { requiresAuth: true, level: PermissionLevel.Admin },
    component: () => import(/* webpackChunkName: "Alert" */ '@/views/Admin.vue'),
  },
  {
    path: '/access-denied',
    name: 'access-denied',
    component: () => import(/* webpackChunkName: "Alert" */ '@/views/AccessDenied.vue'),
  },
  {
    // 404 catch all
    path: '*',
    component: () => import(/* webpackChunkName: "components" */ '@/views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// auth guard
router.beforeEach(Vue.prototype.$auth.authRedirectGuard());
router.beforeEach((to: Route, _, next: Function) => {
  // set the environment in state if its passed in the url
  if (to.params.env) {
    const upperEnv = to.params.env.toUpperCase();
    if (ENVIRONMENT_TYPES.some(s => s === upperEnv)) {
      store.commit(SET_ENVIRONMENT, upperEnv);
    } else {
      store.commit(SET_ENVIRONMENT, 'PRD');
    }
  }

  Vue.prototype.$auth.isAuthenticated().then((result: Boolean) => {
    store.commit(SET_LOGIN_STATUS, result);
  });

  next();
});

export default router;
