import Http from './Http';
import { ClientConfigurationsModal } from '../models';

class ClientConfigurationsService {
  readonly params = {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  async getAll(selectedEnvirnoment: string): Promise<ClientConfigurationsModal[]> {
    try {
      const response = await this.http.get(`/api/clientconfigurations/${selectedEnvirnoment}`);
      return ClientConfigurationsModal.deserializeList(response.data.data);
    } catch (error) {
      return [];
    }
  }
}

export default new ClientConfigurationsService();
