



























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ComponentMetric } from '../../models';
import { ComponentStatus } from '../../enums';
import ThemeColor from '../../assets/theme-color';
import { getStatusIcon, getStatusColour } from '../../helpers/Common';

@Component
export default class ComponentTiles extends Vue {
  @Prop({ type: Array, default: [] })
  public metrics: ComponentMetric[];
  @Prop({ type: String, default: [] })
  public title: '';
  @Prop({ type: Boolean, default: false })
  public isAdmin!: Boolean | null;

  isLoading: boolean = false;

  get toolbarColour(): string {
    const failingMetrics: ComponentMetric[] = this.isAdmin
      ? this.$store.getters.enabledAdminMetrics
      : this.$store.getters.enabledMetrics
        .filter((f: ComponentMetric) => {
          if (!f.isValid) {
            return false;
          }

          if (f.status === ComponentStatus.Down || f.status === ComponentStatus.Unknown) {
            return true;
          }

          return false;
        });
    if (failingMetrics.length > 0) {
      const hasCriticalFailing = failingMetrics.some((s: ComponentMetric) => s.critical === true);
      return hasCriticalFailing
        ? ThemeColor.redColor
        : ThemeColor.amberColor;
    }
    return ThemeColor.greenColor;
  }

  getFriendlyStatus(status: ComponentStatus): string {
    switch (status) {
      case ComponentStatus.Up: return 'Working';
      case ComponentStatus.Down: return 'Currently down';
      default: return 'Unknown';
    }
  }

  editComponent(id: string): void {
    this.$router.push(`../components/${id}/edit`);
    return this.$store.state.isLoggedIn;
  }
  getStatusIcon(status: ComponentStatus): string {
    return getStatusIcon(status);
  }

  getStatusColour(status: ComponentStatus): string {
    return getStatusColour(status);
  }

  getTypeIcon(type: string): string {
    switch (type) {
      case 'API': return 'fas fa-cogs';
      case 'WindowsService': return 'fas fa-server';
      case 'Website': return 'fas fa-columns';
      case 'Information': return 'fa fa-info-circle';
      default: return 'fas fa-cogs';
    }
  }
}
