import Urls from './Urls';

export default class Component {
  id: string;
  type: string;
  name: string;
  namespace: string;
  description: string;
  urls: Urls;
  critical: boolean;
  enabled: boolean;
  appSettingPaths: object;
  isLocked: boolean;

  constructor(data?: any) {
    this.id = (data && data.id) || '';
    this.type = (data && data.type) || '';
    this.name = (data && data.name) || '';
    this.namespace = (data && data.namespace) || '';
    this.description = (data && data.description) || '';
    this.urls = (data && data.urls) || new Urls();
    this.critical = (data && data.critical) || false;
    this.enabled = (data && data.enabled) || false;
    this.appSettingPaths = (data && data.appSettingPaths) || '';
    this.isLocked = (data && data.isLocked) || false;
  }

  hasUrl(env: string): boolean {
    if (this.getLink(env) && this.getLink(env).toLowerCase() !== 'unknown url') {
      return true;
    }
    return false;
  }

  getLink(env: string): string {
    switch (env) {
      case 'dev': return this.urls.dev;
      case 'tst': return this.urls.test;
      case 'stg': return this.urls.stage;
      case 'prd': return this.urls.prod;
      default: return 'Unknown URL';
    }
  }

  getType(): number {
    switch (this.type) {
      case 'API': return 0;
      case 'WindowsService': return 1;
      case 'Website': return 2;
      case 'Information': return 3;
      default: return 0;
    }
  }

  serialize(): any {
    return {
      Id: (this.id) || null,
      Type: this.getType(),
      Name: this.name,
      Namespace: this.namespace,
      Description: this.description,
      Critical: this.critical,
      Enabled: this.enabled,
      AppSettingPaths: this.appSettingPaths,
      IsLocked: this.isLocked,
      Urls: {
        Prd: this.urls.prod,
        Dev: this.urls.dev,
        Stg: this.urls.stage,
        Tst: this.urls.test,
      },
    };
  }

  static deserialize(data: any): Component {
    const componentData: Partial<Component> = {
      id: (data && data.id),
      type: (data && data.type),
      name: (data && data.name),
      namespace: (data && data.namespace),
      description: (data && data.description),
      critical: (data && data.critical),
      enabled: (data && data.enabled),
      appSettingPaths: (data && data.appSettingPaths),
      isLocked: (data && data.isLocked),
      urls: new Urls(data && data.urls),
    };
    return new Component(componentData);
  }

  public static deserializeList(data: any[]): Component[] {
    const components: Component[] = [];
    Array.isArray(data) && data.map(i => components.push(Component.deserialize(i)));
    return components;
  }
}
