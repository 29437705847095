import moment from 'moment';
import { Frameworks } from '@/enums';

export default class Framework {
  id: string;
  name: string;
  framework: Frameworks;
  version: string;
  environment: string;
  createdDate: string;

  constructor(data?: any) {
    this.id = (data && data.id) || '';
    this.name = (data && data.name) || '';
    this.framework = (data && data.framework) || '';
    this.version = (data && data.version) || '';
    this.environment = (data && data.environment) || '';
    this.createdDate = (data && data.createdDate) || '';
  }

  get createdOn(): string {
    return this.createdDate && moment.utc(this.createdDate).format('DD/MM/YY HH:MM:ss');
  }

  public static deserialize(data?: any): Framework {
    const frameworkData: Partial<Framework> = {
      id: data.id,
      name: data.name,
      framework: data.framework,
      version: data.version,
      environment: data.environment,
      createdDate: data.createdDate,
    };
    return new Framework(frameworkData);
  }

  public static deserializeList(data?: any[]): Framework[] {
    const frameworksData: Framework[] = [];
    Array.isArray(data) && data.map(i => frameworksData.push(Framework.deserialize(i)));
    return frameworksData;
  }


  getFrameworkIcon(framework: Frameworks, version: string): string {
    const images = require.context('../assets/', false, /\.png$/);
    switch (framework) {
      case Frameworks.NETFramework: return images('./dotnetframework.png');
      case Frameworks.NETCoreApp:
        if (version === '5.0') return images('./dotnet5.png');
        if (version === '6.0') return images('./dotnet6.png');
        return images('./dotnetcore.png');
      default: return '';
    }
  }
}
