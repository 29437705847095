// all regex's:

const regex = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneNumber: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
  urls: /^(http|https|ftp):\/\/(www|[a-zA-Z0-9!@#$%^&*)(+=._-]+\.)?[a-zA-Z0-9!@#$%^&*)(+=._-]+\.([a-zA-Z]{2,4})\/?/,
  connectionString: /(www|[a-zA-Z0-9!@#$%^&*)(+=._-]+\.) ?[a-zA-Z0-9!@#$%^&*)(+=._-]+\.([a-zA-Z]{2,4})\/?/,
  onlyCharacters: /^[a-zA-Z-_ ]*$/,
  namespace: /^([a-zA-Z-_ ]+(?:\.?[a-zA-Z]+)*)$/,
};

export default regex;
