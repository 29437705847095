
























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ConfirmationDialog from '../shared/ConfirmationDialog.vue';
import OverlayLoader from '../shared/OverlayLoader.vue';
import friendlyBool from '../../helpers/Common';
import { Incident } from '../../models';

@Component({
  components: {
    ConfirmationDialog,
    OverlayLoader,
  },
})
export default class IncidentsList extends Vue {
  isLoading: boolean = false;
  size: number = 20;

  @Watch('environment')
  onEnvironmentChange(value: string, old: string) {
    this.isLoading = true;
    this.$store.dispatch('loadLatestIncidents', this.loadIncidentsPayload)
      .then(() => {
        this.isLoading = false;
      });
  }

  get loadIncidentsPayload(): any {
    return {
      size: this.size,
      env: this.environment,
    };
  }

  get environment(): string {
    return this.$store.state.environment;
  }

  get incidents(): Incident[] {
    return this.$store.state.incidents;
  }

  mounted(): void {
    this.isLoading = true;
    this.$store.dispatch('loadLatestIncidents', this.loadIncidentsPayload)
      .then(() => {
        this.isLoading = false;
      });
  }

  friendlyBool(input: boolean): string {
    return friendlyBool(input);
  }

  resolve(incident: Incident): void {
    this.isLoading = true;
    this.$store.dispatch('resolveIncident', incident)
      .then(() => {
        this.$store.dispatch('loadLatestIncidents', this.loadIncidentsPayload)
          .then(() => {
            this.isLoading = false;
          });
      });
  }
}
