export default class Postman {
  id: string;
  name: string;
  owner: string;
  uid: string;

  constructor(data?: any) {
    this.id = (data && data.id) || '';
    this.name = (data && data.name) || '';
    this.owner = (data && data.owner) || '';
    this.uid = (data && data.uid) || '';
  }

  public static deserialize(data?: any): Postman {
    const clientData: Partial<Postman> = {
      id: data.id,
      name: data.name,
      owner: data.owner,
      uid: data.uid,
    };
    return new Postman(clientData);
  }

  public static deserializeList(data?: any[]): Postman[] {
    const clientsData: Postman[] = [];
    Array.isArray(data) && data.map(i => clientsData.push(Postman.deserialize(i)));
    return clientsData;
  }
}
