
export default class ConnectionReportModel {
    name: string;
    host: string;
    status: string;
    type: string;
    isMatched: boolean;
    refHost: string;
    success: boolean;

    constructor(data?: any) {
      this.name = (data && data.name) || '';
      this.host = (data && data.host) || '';
      this.status = (data && data.status) || '';
      this.type = (data && data.type) || '';
      this.isMatched = (data && data.isMatched) || false;
      this.refHost = (data && data.refHost) || '';
      this.success = (data && data.success) || false;
    }

    public static deserializeList(data: any[]): ConnectionReportModel[] {
      const connections: ConnectionReportModel[] = [];
      Array.isArray(data) && data.map(i => connections.push(i));
      return connections;
    }
}
