import Http from './Http';
import StatusSettings from '../models/StatusSettings';

class StatusSettingsService {
  readonly params = {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  async get(): Promise<StatusSettings> {
    try {
      const response = await this.http.get('/api/settings');
      return StatusSettings.deserialize(response.data.data);
    } catch (_) {
      return new StatusSettings();
    }
  }

  async updateAlerts(enabled: boolean, environment: string): Promise<boolean> {
    try {
      const url: string = enabled
        ? `/api/settings/alerts/enable?env=${environment}`
        : `/api/settings/alerts/disable?env=${environment}`;
      const response = await this.http.put(url, null);
      return response.data.data;
    } catch (_) {
      return false;
    }
  }
}

export default new StatusSettingsService();
