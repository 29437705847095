export default class ConfigurationData {
    name: string;
    value: string;
    type: string[];

    constructor(data?: any) {
      this.name = (data && data.name) || '';
      this.value = (data && data.value) || '';
      this.type = (data && data.type) || [];
    }

    public static deserialize(data?: any): ConfigurationData {
      const clientConfiguration: Partial<ConfigurationData> = {
        name: data.name,
        value: data.value,
        type: data.type,
      };

      return new ConfigurationData(clientConfiguration);
    }

    public static deserializeList(data?: any[]): ConfigurationData[] {
      const configData: ConfigurationData[] = [];
      Array.isArray(data) && data.map(i => configData.push(ConfigurationData.deserialize(i)));
      return configData;
    }
}
