




















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ConfirmationDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  public showDialog!: Boolean | null;

  @Prop({ type: String, default: null })
  public deleteType: string | null;

  get message(): string {
    if (this.deleteType) {
      return ` the ${this.deleteType}?`;
    }
    return '?';
  }

  emitSubmit() {
    this.$emit('confirmationSubmit');
  }

  emitCancel() {
    this.$emit('confirmationCancel');
  }
}
