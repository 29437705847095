












































































































































































import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { PermissionLevel } from '../../enums';
import VersionService from '../../services/VersionService';
import Version from '../../models/Version';
import UserService from '../../services/UserService';

@Component
export default class Navigation extends Vue {
  @Prop({ type: Boolean, default: false })
  public drawer!: Boolean | null;

  get env() {
    if (process.env.VUE_APP_ENV) {
      return process.env.VUE_APP_ENV;
    }
    return 'Unknown';
  }

  get version(): Version {
    return this.$store.state.version;
  }

  get isAdmin(): boolean {
    return this.$store.state.isLoggedIn
      && UserService.hasPermission(PermissionLevel.Admin);
  }

  get isLoggedIn(): boolean {
    return this.$store.state.isLoggedIn;
  }

  get environment(): string {
    return this.$store.state.environment;
  }

  goTo(area: string): string {
    return `/${area}/${this.environment}`;
  }
}
