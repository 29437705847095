import { PermissionLevel } from '@/enums';

export class StatusUser {
  permission: PermissionLevel;
  id: string;
  name: string;
  email: string;
  groupName: string;

  constructor(data?: any) {
    this.permission = (data && data.permission) || null;
    this.id = (data && data.id) || '';
    this.name = (data && data.name) || '';
    this.email = (data && data.email) || '';
    this.groupName = (data && data.groupName) || '';
  }

  public static deserialize(data?: any, permission?: PermissionLevel) {
    if (!data) {
      return new StatusUser();
    }

    const user: Partial<StatusUser> = {
      permission,
      id: data.id,
      name: data.displayName,
      email: data.email,
      groupName: data.groupName,
    };

    return new StatusUser(user);
  }

  public static deserializeList(data: any[], permission?: PermissionLevel): StatusUser[] {
    const statusUsers: StatusUser[] = [];
    Array.isArray(data) && data.map(i => statusUsers.push(StatusUser.deserialize(i, permission)));
    return statusUsers;
  }
}

export default StatusUser;
