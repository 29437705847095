import Http from './Http';
import { PostmanCollectionModel, PostmanEnvironmentModel, PostmanReportModal } from '../models';

class PostmanService {
  readonly params = {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  async getCollections(): Promise<PostmanCollectionModel[]> {
    try {
      const response = await this.http.get('/api/postman/collections');
      return PostmanCollectionModel.deserializeList(response.data.data);
    } catch (error) {
      return [];
    }
  }

  async getEnvironments(): Promise<PostmanEnvironmentModel[]> {
    try {
      const response = await this.http.get('/api/postman/environments');
      return PostmanEnvironmentModel.deserializeList(response.data.data);
    } catch (error) {
      return [];
    }
  }

  async getReports(): Promise<PostmanReportModal[]> {
    try {
      const response = await this.http.get('/api/postman/reports');
      return PostmanReportModal.deserializeList(response.data.data);
    } catch (error) {
      return [];
    }
  }

  async saveReport(postman: PostmanReportModal): Promise<any> {
    const http = new Http(this.params);
    try {
      const reqBody = postman.serialize();
      const response = await http.post('/api/postman/reports', reqBody);
      return response.data.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new PostmanService();
