


































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { ComponentModel } from '../../models';
import ConfirmationDialog from '../shared/ConfirmationDialog.vue';
import OverlayLoader from '../shared/OverlayLoader.vue';
import { PermissionLevel } from '../../enums';
import UserService from '../../services/UserService';
import friendlyBool from '../../helpers/Common';
import { ENVIRONMENT_TYPES } from '../../helpers/Constants';

@Component({
  components: {
    ConfirmationDialog,
    OverlayLoader,
  },
})
export default class ComponentList extends Vue {
  readonly environments = ENVIRONMENT_TYPES;
  showDialog: boolean = false;
  selectedComponentId: string;
  isLoading: boolean = false;

  @Watch('environment')
  onEnvironmentChange(value: string, old: string) {
    this.isLoading = true;
    this.$store.dispatch('loadComponents')
      .then(() => {
        this.isLoading = false;
      });
  }

  copyText(link: string): void {
    const el = document.createElement('textarea');
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  isGetLink(item: ComponentModel): boolean {
    return typeof item.getLink !== 'undefined';
  }

  get environment(): string {
    return this.$store.state.environment.toLowerCase();
  }

  get components(): ComponentModel[] {
    return this.$store.state.components.sort((a: ComponentModel, b: ComponentModel) => {
      return a.name < b.name
        ? -1
        : a.name > b.name
          ? 1
          : 0;
    });
  }

  get canEdit(): boolean {
    return UserService.hasPermission(PermissionLevel.ReadWrite);
  }

  mounted(): void {
    this.isLoading = true;
    this.$store.dispatch('loadComponents')
      .then(() => {
        this.isLoading = false;
      });
  }

  friendlyBool(input: boolean): string {
    return friendlyBool(input);
  }

  editComponent(component: ComponentModel): void {
    this.$router.push(`../components/${component.id}/edit`);
  }

  confirmDelete(component: ComponentModel) {
    this.selectedComponentId = component.id;
    this.showDialog = true;
  }

  deleteComponent(): void {
    this.isLoading = true;
    this.$store.dispatch('deleteComponent', this.selectedComponentId)
      .then(() => {
        this.isLoading = false;
      });
    this.selectedComponentId = '';
    this.showDialog = false;
  }

  cancelDelete(): void {
    this.selectedComponentId = '';
    this.showDialog = false;
  }
}
