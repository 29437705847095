import Http from './Http';
import { FrameworkModal } from '../models';

class FrameworkService {
  readonly params = {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  async getAll(selectedEnvirnoment: string): Promise<FrameworkModal[]> {
    try {
      const response = await this.http.get(`/api/clientconfigurations/framework/${selectedEnvirnoment}`);
      return FrameworkModal.deserializeList(response.data.data);
    } catch (error) {
      return [];
    }
  }
}

export default new FrameworkService();
