import { PermissionLevel } from '@/enums';
import { ActiveDirectoryUser, StatusUser } from '@/models';
import Config from '@/helpers/Config';
import Http from './Http';

class UserService {
  readonly permissionKey: string = 'permissionKey';
  readonly params = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  hasPermission(requiredLevel: PermissionLevel): boolean {
    const store = localStorage.getItem(this.permissionKey);
    const userLevel = store as PermissionLevel;

    if (userLevel === PermissionLevel.Admin) {
      return true;
    }

    if (requiredLevel === PermissionLevel.ReadWrite && userLevel === PermissionLevel.ReadWrite) {
      return true;
    }

    if (requiredLevel === PermissionLevel.Read && userLevel === PermissionLevel.ReadWrite) {
      return true;
    }

    if (requiredLevel === PermissionLevel.Read && userLevel === PermissionLevel.Read) {
      return true;
    }

    return false;
  }

  // used for searching users to add to the user subscriptions / alerts
  async searchUsers(query: string): Promise<ActiveDirectoryUser[]> {
    try {
      const response = await this.http.get(`/api/users?query=${query}&limit=1`);
      return ActiveDirectoryUser.deserializeList(response.data.data);
    } catch (err) {
      return [];
    }
  }

  async setPermission(id: string): Promise<void> {
    try {
      const response = await this.http.get(`/api/users/${id}/permissions`);
      const { permission } = response.data.data;
      localStorage.setItem(this.permissionKey, permission);
    } catch (err) {
      throw err;
    }
  }

  async loadStatusUsers(): Promise<StatusUser[]> {
    const response = await this.http.get('/api/users/statusapp');
    return [
      ...StatusUser.deserializeList(response.data.data.writeUsers, PermissionLevel.ReadWrite),
      ...StatusUser.deserializeList(response.data.data.readUsers, PermissionLevel.Read),
    ];
  }

  clearState(): void {
    localStorage.removeItem(this.permissionKey);
  }
}

export default new UserService();
