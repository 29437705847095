
















import Vue from 'vue';
import Component from 'vue-class-component';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import Layout from './components/layout/Layout.vue';
import { SET_VERSION, SET_LOGIN_STATUS, UPDATE_POSTMAN_REPORT } from './store/Mutations';
import UserService from './services/UserService';
import VersionService from './services/VersionService';
import Version from './models/Version';

@Component({
  components: {
    Layout,
  },
})
export default class App extends Vue {
  showSnackbar: boolean = false;
  second: number = 5;
  timeout: number = 5000;
  snackbarContent: string = '';

  get version(): string {
    return this.$store.state.version.formattedVersion;
  }

  mounted() {
    this.listenForAppUpdates();
    this.loadVersion();
  }

  loadVersion(): void {
    VersionService.getAppVersion().then((version: Version) => {
      this.$store.commit(SET_VERSION, version);
    });
  }

  listenForAppUpdates(): Promise<void> {
    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_URL}/hubs/notifications`, {
        accessTokenFactory: () => {
          return this.$auth.getAccessToken();
        },
      })
      .configureLogging(`${process.env.VUE_APP_PUSHNOTIFICATIONS_LOGGING_LEVEL}`)
      .withAutomaticReconnect()
      .build();

    connection.on('appUpdated', (data) => {
      const version: Version = new Version(data);
      this.$store.commit(SET_VERSION, version);
      this.snackbarContent = 'Status App will update in {{ second }}<br/>Version: {{ version }}';
      const intervalId = setInterval(() => {
        this.showSnackbar = true;
        this.second--;
        if (this.second === 0) {
          clearInterval(intervalId);
          window.location.reload(true);
        }
      }, 1000);
    });

    connection.on('configUpdated', () => {
      if (this.$route.meta && this.$route.meta.requiresAuth) {
        this.snackbarContent = 'Heartbeat configuration has been updated';
        this.showSnackbar = true;
      }
    });

    connection.on('generatePostmanReport', (data) => {
      if (this.$route.meta && this.$route.meta.requiresAuth) {
        const parseData = JSON.parse(data);
        this.snackbarContent = `Postman Report '${parseData.id}' is ${parseData.status}.`;
        if (parseData.status === 'COMPLETED') {
          this.$store.commit(UPDATE_POSTMAN_REPORT);
        }
        this.showSnackbar = true;
      }
    });

    return connection.start();
  }
}
