import Http from './Http';
import { Incident } from '../models';

class IncidentService {
  readonly params = {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  async getLatest(size: number, env: string): Promise<Incident[]> {
    try {
      const response = await this.http.get(`/api/incidents/latest?size=${size}&environment=${env}`);
      return Incident.deserializeList(response.data.data);
    } catch (_) {
      return [];
    }
  }

  async resolveIncident(id: string): Promise<boolean> {
    try {
      const response = await this.http.put(`/api/incidents/resolve/${id}`, null);
      return response.data.data;
    } catch (_) {
      return false;
    }
  }
}

export default new IncidentService();
