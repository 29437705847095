import Http from '@/services/Http';
import { UserSubscription } from '@/models';

class UserSubscriptionService {
  readonly params = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  async getAll() : Promise<UserSubscription[]> {
    try {
      const response = await this.http.get('/api/subscriptions');
      return UserSubscription.deserializeList(response.data.data);
    } catch (error) {
      return [];
    }
  }

  async update(userSubscription: UserSubscription): Promise<boolean> {
    try {
      const response = await this.http.put('/api/subscriptions', userSubscription);
      return response.data.data;
    } catch (error) {
      return false;
    }
  }

  async delete(id: string) : Promise<boolean> {
    try {
      const response = await this.http.delete(`/api/subscriptions/${id}`);
      return response.status === 204;
    } catch {
      return false;
    }
  }

  async save(userSubscription: UserSubscription): Promise<any> {
    const http = new Http(this.params);
    try {
      const response = await http.post('/api/subscriptions', userSubscription);
      return response.data.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new UserSubscriptionService();
