import Urls from './Urls';

export default class Connection {
  id: string;
  type: string;
  name: string;
  description: string;
  values: Urls;

  constructor(data?: any) {
    this.id = (data && data.id) || '';
    this.type = (data && data.type) || '';
    this.name = (data && data.name) || '';
    this.description = (data && data.description) || '';
    this.values = (data && data.values) || new Urls();
  }

  hasUrl(env: string): boolean {
    if (this.getLink(env) && this.getLink(env).toLowerCase() !== 'unknown url') {
      return true;
    }
    return false;
  }

  getLink(env: string): string {
    switch (env) {
      case 'dev': return this.values.dev;
      case 'tst': return this.values.test;
      case 'stg': return this.values.stage;
      case 'prd': return this.values.prod;
      default: return 'Unknown URL';
    }
  }

  getType(): number {
    switch (this.type) {
      case 'AZURE': return 0;
      case 'MSSQL': return 1;
      case 'ORACLE': return 2;
      case 'REDIS': return 3;
      case 'MONGO': return 4;
      case 'OTHER': return 5;
      default: return 0;
    }
  }

  serialize(): any {
    return {
      Id: (this.id) || null,
      Type: this.getType(),
      Name: this.name,
      Description: this.description,
      Values: {
        Prd: this.values.prod,
        Dev: this.values.dev,
        Stg: this.values.stage,
        Tst: this.values.test,
      },
    };
  }

  static deserialize(data: any): Connection {
    const connectionData: Partial<Connection> = {
      id: (data && data.id),
      type: (data && data.type),
      name: (data && data.name),
      description: (data && data.description),
      values: new Urls(data && data.values),
    };
    return new Connection(connectionData);
  }

  public static deserializeList(data: any[]): Connection[] {
    const connections: Connection[] = [];
    Array.isArray(data) && data.map(i => connections.push(Connection.deserialize(i)));
    return connections;
  }
}
