export default class Urls {
  dev: string;
  test: string;
  stage: string;
  prod: string;
  constructor(data?: any) {
    this.dev = (data && data.dev) || '';
    this.test = (data && data.tst) || '';
    this.stage = (data && data.stg) || '';
    this.prod = (data && data.prd) || '';
  }
}
