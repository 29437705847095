











































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import Navigation from './Navigation.vue';
import { SET_ENVIRONMENT } from '../../store/Mutations';
import { ENVIRONMENT_TYPES } from '../../helpers/Constants';

declare const screen: any;
declare const window: any;

@Component({
  components: {
    Navigation,
  },
})
export default class Header extends Vue {
  private drawer : Boolean = false;
  isFullScreen: boolean = false;
  selectedEnvironment: string = 'PRD';

  @Watch('routeEnvironment')
  updateEnvironment(): void {
    if (this.$route.params.env) {
      const { env } = this.$route.params;
      this.selectedEnvironment = env;
      this.$store.commit(SET_ENVIRONMENT, env);
    }
  }

  get routeEnvironment(): string {
    return this.$route.params.env;
  }

  get environments(): string[] {
    return ENVIRONMENT_TYPES;
  }

  mounted(): void {
    if (this.$route.params.env) {
      const { env } = this.$route.params;
      this.selectedEnvironment = env;
      this.$store.commit(SET_ENVIRONMENT, env);
    }

    window.addEventListener('resize', () => this.updateFullScreenState());
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.updateFullScreenState);
  }

  setEnvironment(env: string): void {
    this.selectedEnvironment = env;

    // update the store's environment to selected value.
    this.$store.commit(SET_ENVIRONMENT, env);

    // ignore same environment changes
    if (!this.$route.params.env
     || this.selectedEnvironment.toLowerCase() === this.$route.params.env.toLowerCase()) {
      return;
    }

    const path = `/${this.$route.name}/${env.toLowerCase()}`;
    this.$router.push({ path });
  }

  toggleDrawer(): void {
    this.drawer = !this.drawer;
  }

  updateFullScreenState(): void {
    this.isFullScreen = this.isBrowserFullScreen();
  }

  fullScreenHandler(): void {
    if (!this.isFullScreen) {
      this.maximize();
    } else {
      this.minimize();
    }
  }

  maximize(): void {
    const element: any = document.body;

    if (element.requestFullscreen) {
      element.requestFullscreen().then(() => { this.isFullScreen === true; });
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else {
      alert('Sorry, your browser doesnt support Fullscreen mode');
    }
    this.isFullScreen = true;
  }

  minimize(): void {
    const documentElement: any = document;

    if (documentElement.exitFullscreen) {
      documentElement.exitFullscreen().then(() => { this.isFullScreen === false; });
    } else if (documentElement.msExitFullscreen) {
      documentElement.msExitFullscreen();
    } else if (documentElement.mozCancelFullScreen) {
      documentElement.mozCancelFullScreen();
    } else if (documentElement.webkitExitFullscreen) {
      documentElement.webkitExitFullscreen();
    }
    this.isFullScreen = false;
  }

  isBrowserFullScreen(): boolean {
    const reserve: number = 50;
    return (screen.availHeight || screen.height - reserve) <= window.innerHeight;
  }

  get userLogin(): string {
    const token = localStorage.getItem('okta-token-storage');
    if (token) {
      const okta = JSON.parse(token);
      if (okta.idToken) {
        const user = okta.idToken.claims.preferred_username;
        if (user !== undefined || user !== null) {
          const userName = user.split('@');
          return userName[0];
        }
      }
    }
    return '';
  }

  get isLoggedIn(): boolean {
    return this.$store.state.isLoggedIn;
  }
}
