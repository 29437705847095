import moment from 'moment';

export default class PostmanReport {
  id: string;
  collectionUid: string;
  environmentUid: string;
  collectionName: string;
  environmentName: string;
  status: string;
  createdOn: string;

  constructor(data?: any) {
    this.id = (data && data.id) || '';
    this.collectionUid = (data && data.collectionUid) || '';
    this.environmentUid = (data && data.environmentUid) || '';
    this.collectionName = (data && data.collectionName) || '';
    this.environmentName = (data && data.environmentName) || '';
    this.status = (data && data.status) || '';
    this.createdOn = (data && data.createdOn) || '';
  }

  get createdDate(): string {
    return this.createdOn && moment.utc(this.createdOn).format('DD/MM/YY HH:mm:ss');
  }

  serialize(): any {
    return {
      collectionUid: (this.collectionUid) || null,
      environmentUid: (this.environmentUid) || null,
      collectionName: this.collectionName,
      environmentName: this.environmentName,
    };
  }

  public static deserialize(data?: any): PostmanReport {
    const reportData: Partial<PostmanReport> = {
      id: data.id,
      collectionUid: data.collectionUid,
      environmentUid: data.environmentUid,
      collectionName: data.collectionName,
      environmentName: data.environmentName,
      status: data.status,
      createdOn: data.createdOn,
    };
    return new PostmanReport(reportData);
  }

  public static deserializeList(data?: any[]): PostmanReport[] {
    const reportsData: PostmanReport[] = [];
    Array.isArray(data) && data.map(i => reportsData.push(PostmanReport.deserialize(i)));
    return reportsData;
  }
}
