export default class Version {
  version: string;
  buildNumber: string;
  dbHost: string;

  constructor(data?: any) {
    this.version = (data && data.version) || '';
    this.buildNumber = (data && data.buildNumber) || '';
    this.dbHost = (data && data.dbHost) || '';
  }

  get formattedVersion(): string {
    return `v${this.version}-${this.buildNumber}`;
  }

  get formattedDbHost(): string {
    return `Db host: ${this.dbHost}`;
  }

  public static deserialize(data?: any): Version {
    const versionData: Partial<Version> = {
      version: data.version,
      buildNumber: data.buildNumber,
      dbHost: data.dbHost,
    };
    return new Version(versionData);
  }
}
