



import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardList from '@/components/dashboard/DashboardList.vue';

@Component({
  components: {
    DashboardList,
  },
})
export default class DashboardView extends Vue {
}
