
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class OverlayLoader extends Vue {
  @Prop({ type: Boolean, default: false })
  public isLoading!: Boolean;

  @Prop({ type: Boolean, default: true })
  public fullSize!: Boolean;

  @Prop({ type: Number, default: 100 })
  public size: Number;
}
