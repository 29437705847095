export default class Subscription {
  type: string;
  enabled: boolean;
  email: string;
  phoneNumber: string;
  environments: string[];

  constructor(data?: any) {
    this.type = (data && data.type) || '';
    this.email = (data && data.email) || '';
    this.phoneNumber = (data && data.phoneNumber) || '';
    this.enabled = (data && data.enabled);
    this.environments = (data && data.environments) || [];
  }

  get value(): string {
    return this.type.toLowerCase() === 'sms'
      ? this.phoneNumber
      : this.email;
  }

  public static deserialize(data?: any): Subscription {
    const userSubscription: Partial<Subscription> = {
      type: data.type,
      email: data.email,
      enabled: data.enabled,
      phoneNumber: data.phoneNumber,
      environments: data.environments,
    };

    return new Subscription(userSubscription);
  }

  public static deserializeList(data?: any[]): Subscription[] {
    const subscriptions: Subscription[] = [];
    Array.isArray(data) && data.map(i => subscriptions.push(Subscription.deserialize(i)));
    return subscriptions;
  }
}
