







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ComponentList from '@/components/component/ComponentList.vue';
import ComponentHeader from '@/components/component/ComponentHeader.vue';

@Component({
  components: {
    ComponentList,
    ComponentHeader,
  },
})
export default class ComponentsView extends Vue {
}
