export default class ActiveDirectoryUser {
  id: string;
  displayName: string;
  mail: string;

  constructor(data?: any) {
    this.id = (data && data.id) || '';
    this.displayName = (data && data.displayName) || '';
    this.mail = (data && data.mail) || '';
  }

  public static deserialize(data?: any): ActiveDirectoryUser {
    const versionData: Partial<ActiveDirectoryUser> = {
      id: data.id,
      displayName: data.displayName,
      mail: data.mail,
    };
    return new ActiveDirectoryUser(versionData);
  }

  public static deserializeList(data?: any[]): ActiveDirectoryUser[] {
    const adUsers: ActiveDirectoryUser[] = [];
    Array.isArray(data) && data.map(i => adUsers.push(ActiveDirectoryUser.deserialize(i)));
    return adUsers;
  }
}
