




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ComponentForm from '@/components/component/ComponentForm.vue';

@Component({
  components: {
    ComponentForm,
  },
})
export default class AddComponentView extends Vue {

}
