export default class StatusSettings {
  app: string;
  alertsEnabledDev: boolean;
  alertsEnabledPrd: boolean;
  alertsEnabledStg: boolean;
  alertsEnabledTst: boolean;

  constructor(data?: any) {
    this.app = (data && data.app) || '';
    this.alertsEnabledDev = (data && data.alertsEnabledDev) || false;
    this.alertsEnabledPrd = (data && data.alertsEnabledPrd) || false;
    this.alertsEnabledStg = (data && data.alertsEnabledStg) || false;
    this.alertsEnabledTst = (data && data.alertsEnabledTst) || false;
  }

  public static deserialize(data?: any): StatusSettings {
    const statusSettings: Partial<StatusSettings> = {
      app: data.app,
      alertsEnabledDev: data.alertsEnabledDev,
      alertsEnabledPrd: data.alertsEnabledPrd,
      alertsEnabledStg: data.alertsEnabledStg,
      alertsEnabledTst: data.alertsEnabledTst,
    };
    return new StatusSettings(statusSettings);
  }
}
