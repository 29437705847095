import Version from '@/models/Version';
import Http from './Http';

class VersionService {
  readonly params = {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  async getAppVersion(): Promise<Version> {
    try {
      const response = await this.http.get('/api/version');
      return Version.deserialize(response.data.data);
    } catch (_) {
      return new Version();
    }
  }
}

export default new VersionService();
