import ConnectionReportModel from './ConnectionReportModel';

export default class ComponentReportModel {
  name: string;
  errorMessage: string;
  correctConnections: number;
  percentage: number;
  success: boolean;
  totalConnections: number;
  connections: ConnectionReportModel[];

  constructor(data?: any) {
    this.name = (data && data.name) || '';
    this.errorMessage = (data && data.errorMessage) || '';
    this.correctConnections = (data && data.correctConnections) || 0;
    this.percentage = (data && data.percentage) || 0;
    this.totalConnections = (data && data.totalConnections) || 0;
    this.success = (data && data.success) || false;
    this.connections = (data && data.connections) || [];
  }

  static deserialize(data: any): ComponentReportModel {
    const uptimeData: Partial<ComponentReportModel> = {
      name: data.name,
      errorMessage: data.errorMessage,
      correctConnections: data.correctConnections,
      percentage: data.percentage,
      success: data.success,
      totalConnections: data.totalConnections,
      connections: ConnectionReportModel.deserializeList(data.connections),
    };

    return new ComponentReportModel(uptimeData);
  }

  public static deserializeList(data: any[]): ComponentReportModel[] {
    const diagnostics: ComponentReportModel[] = [];
    Array.isArray(data) && data.map(i => diagnostics.push(ComponentReportModel.deserialize(i)));
    return diagnostics;
  }
}
