import ComponentReportModel from './ComponentReportModel';

export default class DiagnosticReportModel {
  components: ComponentReportModel[];
  correctComponents: number;
  totalComponents: number;
  totalPercentage: number;
  requested: number;

  constructor(data?: any) {
    this.correctComponents = (data && data.correctComponents) || 0;
    this.totalComponents = (data && data.totalComponents) || 0;
    this.totalPercentage = (data && data.totalPercentage) || 0;
    this.components = (data && data.components) || [];
    this.requested = (data && data.requested) || 0;
  }


  static deserialize(data: any): DiagnosticReportModel {
    const diagnosticData: Partial<DiagnosticReportModel> = {
      correctComponents: data.correctComponents,
      totalComponents: data.totalComponents,
      totalPercentage: data.totalPercentage,
      requested: data.requested,
      components: ComponentReportModel.deserializeList(data.components),
    };

    return new DiagnosticReportModel(diagnosticData);
  }

  public static deserializeList(data: any[]): DiagnosticReportModel[] {
    const diagnostics: DiagnosticReportModel[] = [];
    Array.isArray(data) && data.map(i => diagnostics.push(DiagnosticReportModel.deserialize(i)));
    return diagnostics;
  }
}
