












import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class LoginView extends Vue {
  beforeCreate(): void {
    this.$auth.loginRedirect('/');
  }
}
