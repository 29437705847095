import Vue from 'vue';
import Vuex from 'vuex';
import {
  ComponentService,
  UserService,
  UserSubscriptionService,
  StatusSettingsService,
  IncidentService,
  AlertsService,
  SyncService,
  ConnectionService,
  ClientConfigurationsService,
  PostmanService,
} from '@/services';
import {
  LOAD_COMPONENTS,
  SET_LOGIN_STATUS,
  SET_ENVIRONMENT,
  DELETE_COMPONENT,
  EDIT_COMPONENT,
  ADD_COMPONENT,
  SET_POLLING_ID,
  SET_VERSION,
  LOAD_DASHBOARD_METRICS,
  LOAD_ADMIN_METRICS,
  SET_STATUS_USERS,
  LOAD_USER_SUBSCRIPTIONS,
  EDIT_USER_SUBSCRIPTION,
  DELETE_USER_SUBSCRIPTION,
  SAVE_USER_SUBSCRIPTION,
  LOAD_STATUS_SETTINGS,
  UPDATE_ALERTS_SETTING,
  LOAD_LATEST_INCIDENTS,
  RESOLVE_INCIDENT,
  LOAD_DIAGNOSTICS,
  DELETE_DIAGNOSTICS,
  LOAD_CONNECTIONS,
  ADD_CONNECTION,
  DELETE_CONNECTION,
  EDIT_CONNECTION,
  LOAD_CLIENT_CONFIGURATIONS,
  LOAD_POSTMAN_COLLECTIONS,
  LOAD_POSTMAN_ENVIRONMENTS,
  LOAD_POSTMAN_REPORTS,
  UPDATE_POSTMAN_REPORT,
  SAVE_POSTMAN_REPORT,
} from '@/store/Mutations';
import {
  ComponentModel,
  ComponentMetric,
  StatusUser,
  Version,
  UserSubscription,
  StatusSettings,
  Incident,
  DiagnosticReportModel,
  ConnectionModel,
  ClientConfigurationsModal,
  PostmanReportModal,
  PostmanCollectionModel,
  PostmanEnvironmentModel,
} from '@/models';
import { ENVIRONMENT_TYPES } from '@/helpers/Constants';

Vue.use(Vuex);

// we can split this out into classes & modules if needed later.
export default new Vuex.Store({
  state: {
    components: Array<ComponentModel>(),
    componentMetrics: Array<ComponentMetric>(),
    componentAdminMetrics: Array<ComponentMetric>(),
    userSubscriptions: Array<UserSubscription>(),
    isLoggedIn: false,
    environment: 'dev',
    pollingId: 0,
    version: new Version(),
    users: Array<StatusUser>(),
    statusSettings: new StatusSettings(),
    incidents: Array<Incident>(),
    diagnostics: new DiagnosticReportModel(),
    connections: Array<ConnectionModel>(),
    clientConfigurations: Array<ClientConfigurationsModal>(),
    postmanCollections: Array<PostmanCollectionModel>(),
    postmanEnvironments: Array<PostmanEnvironmentModel>(),
    postmanReports: Array<PostmanReportModal>(),
    needRefresh: false,
  },
  mutations: {
    // user
    [SET_LOGIN_STATUS](state, isLoggedIn: boolean) {
      state.isLoggedIn = isLoggedIn;
    },

    [SET_STATUS_USERS](state, users: StatusUser[]) {
      state.users = users;
    },

    // dashboard
    [LOAD_DASHBOARD_METRICS](state, metrics: ComponentMetric[]) {
      state.componentMetrics = metrics;
    },

    // admin
    [LOAD_ADMIN_METRICS](state, metrics: ComponentMetric[]) {
      state.componentAdminMetrics = metrics;
    },

    // diagnostics
    [LOAD_DIAGNOSTICS](state, diagnostics: DiagnosticReportModel) {
      state.diagnostics = diagnostics;
    },
    [DELETE_DIAGNOSTICS](state) {
      state.diagnostics = new DiagnosticReportModel();
    },

    // components
    [LOAD_COMPONENTS](state, components: ComponentModel[]) {
      state.components = components;
    },
    [DELETE_COMPONENT](state, id: string) {
      state.components = state.components.filter(c => c.id !== id);
    },
    [EDIT_COMPONENT](state, component: ComponentModel) {
      state.components = [...state.components.filter(c => c.id !== component.id), component];
    },
    [ADD_COMPONENT](state, component: ComponentModel) {
      state.components = [...state.components, component];
    },

    // common
    [SET_ENVIRONMENT](state, env: string) {
      state.environment = env;
    },
    [SET_POLLING_ID](state, value: number) {
      state.pollingId = value;
    },
    [SET_VERSION](state, version: Version) {
      state.version = version;
    },

    // subscriptions
    [LOAD_USER_SUBSCRIPTIONS](state, userSubscriptions: UserSubscription[]) {
      state.userSubscriptions = userSubscriptions;
    },
    [EDIT_USER_SUBSCRIPTION](state, userSubscription: UserSubscription) {
      state.userSubscriptions = [...state.userSubscriptions
        .filter(c => c.id !== userSubscription.id), userSubscription];
    },
    [DELETE_USER_SUBSCRIPTION](state, id: string) {
      state.userSubscriptions = state.userSubscriptions.filter(c => c.id !== id);
    },
    [SAVE_USER_SUBSCRIPTION](state, userSubscription: UserSubscription) {
      state.userSubscriptions = [...state.userSubscriptions, userSubscription];
    },

    // status settings
    [LOAD_STATUS_SETTINGS](state, settings: StatusSettings) {
      state.statusSettings = settings;
    },
    [UPDATE_ALERTS_SETTING](state, { enabled, environment }) {
      ENVIRONMENT_TYPES.map((env) => {
        if (env === environment) {
          switch (env) {
            case 'TST':
              state.statusSettings.alertsEnabledTst = enabled;
              break;
            case 'PRD':
              state.statusSettings.alertsEnabledPrd = enabled;
              break;
            case 'STG':
              state.statusSettings.alertsEnabledStg = enabled;
              break;
            case 'DEV':
              state.statusSettings.alertsEnabledDev = enabled;
              break;
            default:
              break;
          }
        }
        return state.statusSettings.alertsEnabledDev;
      });
    },

    // incidents
    [LOAD_LATEST_INCIDENTS](state, incidents: Incident[]) {
      state.incidents = incidents;
    },
    [RESOLVE_INCIDENT](state, incident: Incident) {
      state.incidents = [...state.incidents.filter(f => f.id !== incident.id), incident];
    },

    // clientConfigurations
    [LOAD_CLIENT_CONFIGURATIONS](state, clientConfigurations: ClientConfigurationsModal[]) {
      state.clientConfigurations = clientConfigurations;
    },

    // postman
    [LOAD_POSTMAN_COLLECTIONS](state, postman: PostmanCollectionModel[]) {
      state.postmanCollections = postman;
    },
    [LOAD_POSTMAN_ENVIRONMENTS](state, postman: PostmanEnvironmentModel[]) {
      state.postmanEnvironments = postman;
    },
    [LOAD_POSTMAN_REPORTS](state, postman: PostmanReportModal[]) {
      state.postmanReports = postman;
    },
    [UPDATE_POSTMAN_REPORT](state) {
      state.needRefresh = !state.needRefresh;
    },
    [SAVE_POSTMAN_REPORT](state, postman: PostmanReportModal) {
      state.postmanReports = [...state.postmanReports, postman];
    },

    // connections
    [LOAD_CONNECTIONS](state, connections: ConnectionModel[]) {
      state.connections = connections;
    },
    [DELETE_CONNECTION](state, id: string) {
      state.connections = state.connections.filter(c => c.id !== id);
    },
    [EDIT_CONNECTION](state, connection: ConnectionModel) {
      state.connections = [...state.connections.filter(c => c.id !== connection.id), connection];
    },
    [ADD_CONNECTION](state, connection: ConnectionModel) {
      state.connections = [...state.connections, connection];
    },
  },
  actions: {
    // users
    loadStatusUsers({ commit }): Promise<void> {
      return UserService.loadStatusUsers()
        .then((users: StatusUser[]) => commit(SET_STATUS_USERS, users));
    },

    // metrics
    loadDashboardMetrics({ commit }, env: string): Promise<void> {
      return ComponentService.getMetrics(env)
        .then((metrics: ComponentMetric[]) => {
          commit(LOAD_DASHBOARD_METRICS, metrics);
        });
    },

    // diagnostics
    loadDiagnostics({ commit }, selectedData): Promise<void> {
      return ComponentService.getDiagnostics(selectedData.env, selectedData.names)
        .then((diagnostics: DiagnosticReportModel) => commit(LOAD_DIAGNOSTICS, diagnostics));
    },
    deleteDiagnostics({ commit }): Promise<void> {
      return new Promise((resolve, reject) => {
        commit(DELETE_DIAGNOSTICS);
      });
    },
    // components
    loadComponents({ commit }): Promise<void> {
      return ComponentService.getAll()
        .then((components: ComponentModel[]) => commit(LOAD_COMPONENTS, components));
    },
    deleteComponent({ commit }, id: string): Promise<void> {
      return ComponentService.delete(id)
        .then(_ => commit(DELETE_COMPONENT, id));
    },
    saveComponent({ commit }, component): Promise<boolean> {
      return ComponentService.save(component)
        .then((c) => {
          if (c) {
            commit(ADD_COMPONENT, c);
            return true;
          }
          return false;
        });
    },
    updateComponent({ dispatch, commit }, component): Promise<boolean> {
      return ComponentService.update(component)
        .then((result) => {
          if (result) {
            commit(EDIT_COMPONENT, component);
            dispatch('loadComponents');
            return true;
          }
          return false;
        });
    },

    // connections
    loadConnections({ commit }): Promise<void> {
      return ConnectionService.getAll()
        .then((connections: ConnectionModel[]) => commit(LOAD_CONNECTIONS, connections));
    },
    saveConnection({ commit }, connection): Promise<boolean> {
      return ConnectionService.save(connection)
        .then((c) => {
          if (c) {
            commit(ADD_CONNECTION, c);
            return true;
          }
          return false;
        });
    },
    deleteConnection({ commit }, id: string): Promise<void> {
      return ConnectionService.delete(id)
        .then(_ => commit(DELETE_CONNECTION, id));
    },
    updateConnection({ dispatch, commit }, connection): Promise<boolean> {
      return ConnectionService.update(connection)
        .then((result) => {
          if (result) {
            commit(EDIT_CONNECTION, connection);
            dispatch('loadConnections');
            return true;
          }
          return false;
        });
    },

    // user subscriptions
    loadUserSubscriptions({ commit }): Promise<void> {
      return UserSubscriptionService.getAll()
        .then((subscriptions: UserSubscription[]) => {
          commit(LOAD_USER_SUBSCRIPTIONS, subscriptions);
        });
    },
    updateUserSubscription({ commit }, userSubscription): Promise<boolean> {
      return UserSubscriptionService.update(userSubscription)
        .then((result: boolean) => {
          if (result) {
            commit(EDIT_USER_SUBSCRIPTION, userSubscription);
          }
          return result;
        });
    },
    deleteUserSubscription({ commit }, id: string): Promise<void> {
      return UserSubscriptionService.delete(id)
        .then(_ => commit(DELETE_USER_SUBSCRIPTION, id));
    },
    saveUserSubscription({ commit }, userSubscription: UserSubscription) {
      return UserSubscriptionService.save(userSubscription)
        .then((result: boolean) => {
          if (result) {
            commit(SAVE_USER_SUBSCRIPTION, userSubscription);
          }
          return result;
        });
    },

    // status settings
    loadStatusSettings({ commit }): Promise<void> {
      return StatusSettingsService.get()
        .then((result: StatusSettings) => {
          commit(LOAD_STATUS_SETTINGS, result);
        });
    },
    updateAlertsSettings({ commit }, { enabled, environment }): Promise<boolean> {
      return StatusSettingsService.updateAlerts(enabled, environment)
        .then((result: boolean) => {
          commit(UPDATE_ALERTS_SETTING, { enabled, environment });
          return result;
        });
    },

    // ClientConfigurations
    loadClientConfigurations({ commit }, env: string): Promise<void> {
      return ClientConfigurationsService.getAll(env)
        .then((clientConfigurations: ClientConfigurationsModal[]) => {
          commit(LOAD_CLIENT_CONFIGURATIONS, clientConfigurations);
        });
    },

    // Postman
    loadPostmanCollections({ commit }): Promise<void> {
      return PostmanService.getCollections()
        .then((postmanCollections: PostmanCollectionModel[]) => {
          commit(LOAD_POSTMAN_COLLECTIONS, postmanCollections);
        });
    },

    loadPostmanEnvironments({ commit }): Promise<void> {
      return PostmanService.getEnvironments()
        .then((postmanEnvironments: PostmanEnvironmentModel[]) => {
          commit(LOAD_POSTMAN_ENVIRONMENTS, postmanEnvironments);
        });
    },

    // PostmanReport
    loadPostmanReports({ commit }): Promise<void> {
      return PostmanService.getReports()
        .then((postmanReports: PostmanReportModal[]) => {
          commit(LOAD_POSTMAN_REPORTS, postmanReports);
        });
    },

    savePostmanReport({ commit }, postman: PostmanReportModal): Promise<void> {
      return PostmanService.saveReport(postman)
        .then((result: PostmanReportModal) => {
          commit(SAVE_POSTMAN_REPORT, result);
        });
    },

    // incidents
    loadLatestIncidents({ commit }, { size, env }): Promise<void> {
      return IncidentService.getLatest(size, env)
        .then((incidents: Incident[]) => {
          commit(LOAD_LATEST_INCIDENTS, incidents);
        });
    },
    resolveIncident({ commit }, incident: Incident): Promise<void> {
      return IncidentService.resolveIncident(incident.id)
        .then((result: boolean) => {
          if (result) {
            commit(RESOLVE_INCIDENT, incident);
          } else {
            commit(LOAD_LATEST_INCIDENTS, incident);
          }
        });
    },
    testAlert({ commit }, { type, value }): Promise<any> {
      return AlertsService.testAlert(type, value)
        .then((result: string) => {
          return result;
        });
    },
    // admin
    loadStatusServices({ commit }): Promise<void> {
      return SyncService.loadStatusServices()
        .then((response: ComponentMetric[]) => {
          commit(LOAD_ADMIN_METRICS, response);
        });
    },
  },
  getters: {
    // components
    componentById: state => (id: string) => {
      return state.components.find((f: ComponentModel) => f.id === id);
    },
    allMetrics: state => state.componentMetrics,
    enabledMetrics: state => state.componentMetrics.filter((f: ComponentMetric) => f.enabled),
    enabledAdminMetrics: (state) => {
      return state.componentAdminMetrics.filter((f: ComponentMetric) => f.enabled);
    },

    // user subscriptions
    userSubscriptionById: state => (id: string) => {
      return state.userSubscriptions.find((f: UserSubscription) => f.id === id);
    },
    // Connections
    connectionById: state => (id: string) => {
      return state.connections.find((f: ConnectionModel) => f.id === id);
    },
  },
});
