import { AlertType } from '@/enums';
import Http from './Http';

class AlertsService {
  readonly params = {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  async testAlert(type: AlertType, input: string): Promise<string> {
    try {
      const url: string = type === AlertType.Email
        ? `/api/tests/alerts/email?email=${input}`
        : `/api/tests/alerts/sms?phoneNumber=${input}`;
      const response = await this.http.post(url, null);
      return response.data.data;
    } catch (err) {
      return '';
    }
  }
}

export default new AlertsService();
