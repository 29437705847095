import moment from 'moment';
import { ComponentStatus, HeartbeatStatus, MetricbeatStatus } from '@/enums';
import AdditionalInfoModel from './AdditionalInfoModel';

export default class ComponentMetric {
  name: string;
  timeStamp: string;
  countSummary: string;
  componentId: string;
  status: ComponentStatus;
  componentType: string;
  critical: boolean;
  enabled: boolean;
  isValid: boolean;
  additionalInfo: AdditionalInfoModel;

  constructor(data?: any) {
    this.name = (data && data.name) || '';
    this.timeStamp = (data && data.timeStamp) || null;
    this.countSummary = (data && data.countSummary) || null;
    this.status = (data && data.status) || 'unknown';
    this.componentId = (data && data.componentId) || '';
    this.componentType = (data && data.componentType) || '';
    this.critical = (data && data.critical) || false;
    this.enabled = (data && data.enabled) || false;
    this.isValid = (data && data.isValid) || false;
    this.additionalInfo = (data && data.additionalInfo) || null;
  }

  get formattedDate(): string {
    return this.timeStamp && moment(this.timeStamp).format('DD/MM/YY HH:MM:ss');
  }

  static calculateStatus(data: any): ComponentStatus {
    if (data && data.status) {
      switch (data.status) {
        case HeartbeatStatus.Up:
        case MetricbeatStatus.Running:
          return ComponentStatus.Up;
        case HeartbeatStatus.Down:
        case MetricbeatStatus.Stopped:
          return ComponentStatus.Down;
        default: return ComponentStatus.Unknown;
      }
    }
    return ComponentStatus.Unknown;
  }

  static deserialize(data: any): ComponentMetric {
    const uptimeData: Partial<ComponentMetric> = {
      name: data.name,
      timeStamp: data.time,
      countSummary: data.countSummary,
      status: ComponentMetric.calculateStatus(data),
      componentId: data.id,
      componentType: data.componentType,
      critical: data.critical,
      enabled: data.enabled,
      isValid: data.isValid,
      additionalInfo: AdditionalInfoModel.deserialize(data.additionalInfo),
    };

    return new ComponentMetric(uptimeData);
  }

  public static deserializeList(data: any[]): ComponentMetric[] {
    const metrics: ComponentMetric[] = [];
    Array.isArray(data) && data.map(i => metrics.push(ComponentMetric.deserialize(i)));
    return metrics;
  }
}
