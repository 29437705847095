export default class AdditionalInfoModel {
  totalServers: number;
  percentage: number;
  offlineServers: string;

  constructor(data?: any) {
    this.totalServers = (data && data.totalServers) || 0;
    this.percentage = (data && data.percentage) || 0;
    this.offlineServers = (data && data.offlineServers) || '';
  }

  static deserialize(data: any): AdditionalInfoModel {
    if (!data) {
      return new AdditionalInfoModel();
    }
    const additionalInfo: Partial<AdditionalInfoModel> = {
      totalServers: data.totalServers,
      percentage: data.percentage,
      offlineServers: data.offlineServers,
    };

    return new AdditionalInfoModel(additionalInfo);
  }

  public static deserializeList(data: any[]): AdditionalInfoModel[] {
    const diagnostics: AdditionalInfoModel[] = [];
    Array.isArray(data) && data.map(i => diagnostics.push(AdditionalInfoModel.deserialize(i)));
    return diagnostics;
  }
}
