import moment from 'moment';
import ConfigurationData from './ConfigurationData';

export default class ClientConfigurations {
  id: string;
  name: string;
  machineName: string;
  assemblyVersion: string;
  environment: string;
  frameworkVersion: string;
  informationalVersion: string;
  nugetVersion: string;
  description: string;
  createdOn: string;
  configurations: ConfigurationData[]

  constructor(data?: any) {
    this.id = (data && data.id) || '';
    this.name = (data && data.name) || '';
    this.machineName = (data && data.machineName) || '';
    this.assemblyVersion = (data && data.assemblyVersion) || '';
    this.informationalVersion = (data && data.informationalVersion) || '';
    this.description = (data && data.description) || '';
    this.environment = (data && data.environment) || '';
    this.nugetVersion = (data && data.nugetVersion) || '';
    this.frameworkVersion = (data && data.frameworkVersion) || '';
    this.configurations = (data && data.configurations) || [];
    this.createdOn = (data && data.createdOn) || '';
  }

  get createdDate(): string {
    return this.createdOn && moment.utc(this.createdOn).format('DD/MM/YY HH:mm:ss');
  }

  public static deserialize(data?: any): ClientConfigurations {
    const clientData: Partial<ClientConfigurations> = {
      id: data.id,
      name: data.name,
      machineName: data.machineName,
      assemblyVersion: data.assemblyVersion,
      environment: data.environment,
      frameworkVersion: data.frameworkVersion,
      description: data.description,
      informationalVersion: data.informationalVersion,
      nugetVersion: data.nugetVersion,
      createdOn: data.createdOn,
      configurations: ConfigurationData.deserializeList(data.configurations),
    };
    return new ClientConfigurations(clientData);
  }

  public static deserializeList(data?: any[]): ClientConfigurations[] {
    const clientsData: ClientConfigurations[] = [];
    Array.isArray(data) && data.map(i => clientsData.push(ClientConfigurations.deserialize(i)));
    return clientsData;
  }
}
