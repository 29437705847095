import Http from '@/services/Http';
import { ConnectionModel } from '@/models';

class ConnectionService {
  readonly params = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  async getAll(): Promise<ConnectionModel[]> {
    try {
      const response = await this.http.get('/api/connections');
      return ConnectionModel.deserializeList(response.data.data);
    } catch (error) {
      return [];
    }
  }

  async get(id: string): Promise<ConnectionModel> {
    const response = await this.http.get(`/api/connections?id=${id}`);
    return ConnectionModel.deserialize(response.data.data);
  }
  async save(connection: ConnectionModel): Promise<any> {
    const http = new Http(this.params);
    try {
      const reqBody = connection.serialize();
      const response = await http.post('/api/connections', reqBody);
      return response.data.data;
    } catch (err) {
      throw err;
    }
  }

  async update(connection: ConnectionModel): Promise<boolean> {
    const http = new Http(this.params);
    try {
      const reqBody = connection.serialize();
      const response = await http.put('/api/connections', reqBody);
      return response.status === 200;
    } catch (err) {
      throw err;
    }
  }
  async delete(id: string): Promise<boolean> {
    try {
      const response = await this.http.delete(`/api/connections/${id}`);
      return response.status === 204;
    } catch {
      return false;
    }
  }
}

export default new ConnectionService();
