import { ComponentStatus } from '../enums';
import ThemeColor from '../assets/theme-color';

export default function friendlyBool(input: boolean) {
  return input
    ? 'Yes'
    : 'No';
}

// Comparer Function
export function GetSortOrder(prop: string) {
  return (pre: any, next: any) => {
    if (pre[prop].toLowerCase() > next[prop].toLowerCase()) {
      return 1;
    } if (pre[prop].toLowerCase() < next[prop].toLowerCase()) {
      return -1;
    }
    return 0;
  };
}

export function getStatusIcon(status: ComponentStatus): string {
  switch (status.toLowerCase()) {
    case ComponentStatus.Up: return 'mdi-checkbox-marked-circle';
    case ComponentStatus.Down: return 'mdi-close-circle';
    default: return 'mdi-help-circle';
  }
}

export function getStatusColour(status: ComponentStatus): string {
  if (status.toLowerCase() === ComponentStatus.Up) {
    return ThemeColor.greenColor;
  }
  if (status.toLowerCase() === ComponentStatus.Down) {
    return ThemeColor.redColor;
  }
  return ThemeColor.greyColor;
}

export function getDefaultEnvironment(): string {
  if (process.env.VUE_APP_ENV === 'DEV') {
    return 'dev';
  }
  return 'prd';
}
