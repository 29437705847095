import Http from '@/services/Http';
import { ComponentModel, ComponentMetric, DiagnosticReportModel } from '@/models';

class ComponentService {
  readonly params = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  readonly http = new Http(this.params);

  async getAll(): Promise<ComponentModel[]> {
    try {
      const response = await this.http.get('/api/components');
      return ComponentModel.deserializeList(response.data.data);
    } catch (error) {
      return [];
    }
  }

  async get(id: string): Promise<ComponentModel> {
    const response = await this.http.get(`/api/components?id=${id}`);
    return ComponentModel.deserialize(response.data.data);
  }

  async delete(id: string): Promise<boolean> {
    try {
      const response = await this.http.delete(`/api/components/${id}`);
      return response.status === 204;
    } catch {
      return false;
    }
  }

  async save(component: ComponentModel): Promise<any> {
    const http = new Http(this.params);
    try {
      const reqBody = component.serialize();
      const response = await http.post('/api/components', reqBody);
      return response.data.data;
    } catch (err) {
      throw err;
    }
  }

  async update(component: ComponentModel): Promise<boolean> {
    const http = new Http(this.params);
    try {
      const reqBody = component.serialize();
      const response = await http.put('/api/components', reqBody);
      return response.status === 200;
    } catch (err) {
      throw err;
    }
  }

  async getMetrics(env: string): Promise<ComponentMetric[]> {
    try {
      const response = await this.http.get(`/api/components/metrics?env=${env}`);
      return ComponentMetric.deserializeList(response.data.data.metrics);
    } catch (err) {
      throw err;
    }
  }

  async getDiagnostics(selectedEnvirnoment: string, selectedComponent: string[]):
    Promise<DiagnosticReportModel> {
    try {
      const response = await this.http.get(`/api/diagnostics/${selectedEnvirnoment}`,
        {
          params: {
            names: selectedComponent,
          },
        });
      let diagnosticReport: DiagnosticReportModel = new DiagnosticReportModel();
      if (response.data.data != null) {
        diagnosticReport = DiagnosticReportModel.deserialize(response.data.data);
      }
      return diagnosticReport;
    } catch (_) {
      return new DiagnosticReportModel();
    }
  }
}

export default new ComponentService();
